<template>
  <Container center>
    <section class="col-10 col-sm-6 col-lg-4 text-center my-5">
      <h1><b>Ooops!</b></h1>
      <img src="https://vvs.autosyncstudio.com/static/icons/car-crash.png" alt="" class="w-100" style="max-width: 200px">
      <h6 class="text-muted my-2">Are you lost?</h6>
      <p>Go back, for now we dont have this page.</p>
    </section>
  </Container>
</template>

<script>
import Container from "@/components/Container.vue";

export default {
  name: 'NotFound',
  components: {
      Container
  }
}
</script>
